import React from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Home from '../Pages/Home';
import Cases from '../Pages/Cases';
import Projeto from '../Pages/Projeto';
import ScrollToTop from '../ScrollTop';
import NotFound from '../Pages/NotFound';

function RouteList() {
  return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cases" element={<Cases />} />
            <Route path='/case/:cliente' element={<Projeto />} />
            <Route path='/*' element={<NotFound />} />            
        </Routes>
      </BrowserRouter>
  )
}

export default RouteList