import React from 'react'
import Bruno from '../../../Midia/bruno.webp'
import Victor from '../../../Midia/victor.webp'
import Erika from '../../../Midia/erika.webp'
import Diogo from '../../../Midia/diogo.webp'
import Linkedin from '../../../Midia/linkedin.webp'
import './home.css'

function Lideranças() {
  return (
    <section className='sec1' id='liderancas'>
        <div className="vazia"></div>
        <div className="liderancas">
            <div className='titulo'>Principais <span>Lideranças</span></div>
            <div className='lideresGrid'>
                <div className='lisderWarp'>
                    <a href="https://www.linkedin.com/in/bruno-richter-799372a/" target="_blank" rel="noopener noreferrer">
                        <div className='liderContainer'>
                            <div className='liderFoto'><img src={Bruno} alt="Bruno" width={'100%'} height={'100%'} /></div>
                            <div className="liderlinked"><img src={Linkedin} alt="Linkedin" width={'100%'} height={'100%'} /></div>
                            <div className="lidertexto">
                                <div className="lidernome">Bruno Richter</div>
                                <div className="lidercargo">Diretor de Criação</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='lisderWarp'>
                    <a href="https://www.linkedin.com/in/victor-vicente-67555010/" target="_blank" rel="noopener noreferrer">
                        <div className='liderContainer'>
                            <div className='liderFoto'><img src={Victor} alt="Victor" width={'100%'} height={'100%'} /></div>
                            <div className="liderlinked"><img src={Linkedin} alt="Linkedin" width={'100%'} height={'100%'} /></div>
                            <div className="lidertexto">
                                <div className="lidernome">Victor Vicente</div>
                                <div className="lidercargo">Diretor de Criação</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='lisderWarp'>
                    <a href="https://www.linkedin.com/in/erikamillanribeiro/" target="_blank" rel="noopener noreferrer">
                        <div className='liderContainer'>
                            <div className='liderFoto'><img src={Erika} alt="Erika" width={'100%'} height={'100%'} /></div>
                            <div className="liderlinked"><img src={Linkedin} alt="Linkedin" width={'100%'} height={'100%'} /></div>
                            <div className="lidertexto">
                                <div className="lidernome">Erika Millan</div>
                                <div className="lidercargo">Diretora de Atendimento e Mídia</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className='lisderWarp'>
                    <a href="https://www.linkedin.com/in/diogo-azevedo-01a91414/" target="_blank" rel="noopener noreferrer">
                        <div className='liderContainer'>
                            <div className='liderFoto'><img src={Diogo} alt="Diogo" width={'100%'} height={'100%'} /></div>
                            <div className="liderlinked"><img src={Linkedin} alt="Linkedin" width={'100%'} height={'100%'} /></div>
                            <div className="lidertexto">
                                <div className="lidernome">Diogo Azevedo</div>
                                <div className="lidercargo">Diretor de Mídias Digitais</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Lideranças