import React from 'react'
import '../Home/home.css'


function Chamada() {
  return (
    <section className="sec1">
        <div className="container">
            <div className='textosGG'>
                <p  className='textoGG3'>Cases</p>
                <p className='textoGG4'> Inteligência</p>
                <p className='textoGG4'> Criativa</p>
            </div>
        </div>
    </section>
  )
}

export default Chamada