import React from 'react'
import { useParams } from 'react-router-dom'
import cases from  '../../../Json/cases.json'
import './projeto.css'
function ProjetoLista() {

    const params = useParams();
    const projeto = cases.find((cases)=>{return cases.cliente === params.cliente });
  return (
    <section className='projeto'>
      <div className="projetoContainerTxt">
        <div className={`projetoChamada  chamada${projeto.id}`}>
            {projeto.chamada}
        </div>
        <div className="projetoTexto">
          <p><span>{projeto.txts}</span> {projeto.txt}</p>
        </div>

      </div>
      <div className="visual">
        {projeto.id!== 7 && projeto.id!== 3? 
        <div className="projContainer">
          
          {projeto.video?
           [...projeto.video].map((item)=>{ return (
            <div className={item.tp===1? 'videoCase2': "videoCase"} key={item.id}>
                 <iframe src={`${item.url}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title={item.id} allowfullscreen></iframe>
            </div>
           )})
            :''
          }
          {projeto.imagens?
           [...projeto.imagens].map((i)=>{ return (
            <div className="imageCase" key={i.id}>
                 <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
            </div>
           )})
            :''
          }
           {projeto.imagens2?
           
            <div className="imageCase2" >
              {projeto.imagens2.map((i)=>{ return (
              <div className="imageCase2-I"  key={i.id}>
                 <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
              </div>
              )})}
            </div>
           
            :''
          }
          {projeto.imagens3?
           [...projeto.imagens3].map((i)=>{ return (
            <div className="imageCase" key={i.id}>
                 <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
            </div>
           )})
            :''
          }
          {projeto.imagens4?
           
           <div className="imageCase2" >
             {projeto.imagens4.map((i)=>{ return (
             <div className="imageCase2-I"  key={i.id}>
                <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
             </div>
             )})}
           </div>
          
           :''
         }
         {projeto.imagens5?
           [...projeto.imagens5].map((i)=>{ return (
            <div className="imageCase3" key={i.id}>
                 <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
            </div>
           )})
            :''
          }
          {projeto.imagens6?
           
           <div className="imageCase4" >
             {projeto.imagens6.map((i)=>{ return (
             <div className="imageCase4-I"  key={i.id}>
                <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
             </div>
             )})}
           </div>
          
           :''
         }
          {projeto.imagens7?
           [...projeto.imagens7].map((i)=>{ return (
            <div className="imageCase3" key={i.id}>
                 <img src={i.src} alt={i.id} width={"100%"} height={"100%"}/>
            </div>
           )})
            :''
          }
          {projeto.id=== 5?
           <div className="stanley">
              <div className="stanleyCont">
                  <div className="stanleyBloco">
                      <div className="stanleyTitulo">Squad de influenciadores envolvidos</div>
                      <div className="stanleyGrid">
                        {projeto.influ.map((it)=>{return(
                        <div key={it.id} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                          <div className="stanleyCirc" style={{border:0}}><img src={it.img} alt={it.name} width={"100%"} height={'100%'} /></div>
                          <div className="stanleyNome">{it.name}</div>
                        </div>
                          )})}
                      </div>
                  </div>
                  <div className="stanleyBloco">
                      <div className="stanleyTitulo">Big numbers na ativação</div>
                      <div className="stanleyGrid">
                         <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div className="stanleyCirc">
                                <div className="BN1">2.3M</div>
                                <div className="BNtext">Impactos</div>
                            </div>
                         </div>
                         <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div className="stanleyCirc">
                                <div className="BN1">74.8k</div>
                                <div className="BNtext">Shares</div>
                            </div>
                         </div>
                         <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div className="stanleyCirc">
                                <div className="BN1">7.8k</div>
                                <div className="BNtext">Cliques</div>
                            </div>
                         </div>
                         <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div className="stanleyCirc">
                                <div className="BN1">416k</div>
                                <div className="BNtext">Interações</div>
                            </div>
                         </div>
                         <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div className="stanleyCirc">
                                <div className="BN1">18%</div>
                                <div className="BNtext">Engajamento</div>
                            </div>
                         </div>
                      </div>
                  </div>
              </div>
              
           </div>
           :
           ''
          }

        </div>
        :
        <div className="projContainer">
          {projeto.VideoOne? 
          <div className="videoCase">
              <iframe src={`${projeto.VideoOne.url}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title= 'video 1' allowfullscreen></iframe>
           </div>
            :""}
          
            {projeto.imagemA.tp === 'v'?
            <div className="videoCase">
            <iframe src={`${projeto.imagemA.src}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title= 'video 1' allowfullscreen></iframe>
            </div>
            :
            <div className="imageCase">
            <img src={projeto.imagemA.src} alt={projeto.imagemA.src} width={"100%"} height={"100%"}/>
            </div>
            }
          
          <div className="imageCase2">
            <div className="imageCase2-I">
            <iframe src={`${projeto.videoA.url}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title="ibeu 1" allowfullscreen></iframe>
            </div>
            <div className="imageCase2-I">
            <iframe src={`${projeto.videoB.url}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title="ibeu 2" allowfullscreen></iframe>
            </div>
          </div>
          <div className="imageCase">
            <img src={projeto.imagemB.src} alt={projeto.imagemB.src} width={"100%"} height={"100%"}/>
          </div>
          <div className="imageCase2">
            <div className="imageCase2-I">
              <img src={projeto.imagemC.src} alt={projeto.imagemC.src} width={"100%"} height={"100%"}/>
            </div>
            <div className="imageCase2-I">
              <img src={projeto.imagemD.src} alt={projeto.imagemD.src} width={"100%"} height={"100%"}/>
            </div>
          </div>
          {projeto.imagemE && projeto.imagemF?
          <div className="imageCase2">
            <div className="imageCase2-I">
              <img src={projeto.imagemE.src} alt={projeto.imagemE.src} width={"100%"} height={"100%"}/>
            </div>
            <div className="imageCase2-I">
              <img src={projeto.imagemF.src} alt={projeto.imagemF.src} width={"100%"} height={"100%"}/>
            </div>
          </div>
          :''}
          <div className="imageCase3">
            <img src={projeto.imagemG.src} alt={projeto.imagemG.src} width={"100%"} height={"100%"}/>
          </div>
          
          <div className="imageCase2" >

              {projeto.videoC.tp === "i"?
                <div className="imageCase4-I">
               <img src={projeto.videoC.url} alt={projeto.videoC.url} width={"100%"} height={"100%"}/>
               </div>
               :
               <div className="imageCase2-I">
               <iframe src={`${projeto.videoC.url}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title="ibeu 3" allowfullscreen></iframe>
               </div>
            }
            

            
            {projeto.videoD.tp === "i"?
                <div className="imageCase4-I">
               <img src={projeto.videoD.url} alt={projeto.videoD.url} width={"100%"} height={"100%"}/>
               </div>
               :
               <div className="imageCase2-I">
               <iframe src={`${projeto.videoD.url}`} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title="ibeu 3" allowfullscreen></iframe>
               </div>
            }
            

          </div>
        </div>
        }
      </div>

    </section>

  )
}

export default ProjetoLista