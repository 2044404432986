import React from 'react'
function Video() {


  return (
    <section className='sec1'>
        <div className='video'>
        <iframe src="https://player.vimeo.com/video/686008021?h=0467084020" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" title='Camisa 10' allowfullscreen></iframe>
        </div>

    </section>
  )
}

export default Video