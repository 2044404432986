import React, { useRef,  useState } from 'react';
import emailjs from '@emailjs/browser';
import '../Pages/Home/home.css'

function Formulario() {

    const form = useRef();
    const [envio, setEnvio]=useState(false);
    const [erro, setErro]=useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setErro(false)
    setEnvio(false)
    emailjs
      .sendForm('service_16xfjss', 'template_pgpaxg3', form.current, {
        publicKey: 'Qk83wdvhzLAPrvmay',
      })
      .then(
        () => {
          setEnvio(true)
          document.getElementById('formnome').value=''
          document.getElementById('formemail').value=''
          document.getElementById('formtexto').value=''
        },
        (error) => {
          setErro(true)
        },
      );
  };
  return (
    <section className='sec1' id='contato'>
        <div className="vazia"></div>
        <div className='formulario'>
            <div className='titulo'>Fale com a c<span>10</span></div>
            <form ref={form} onSubmit={sendEmail} className='form' >
                
                <input type="text" name="from_name" id='formnome' required placeholder='Nome' />
                
                <input type="email" name="from_email" id='formemail' required placeholder='Email'/>
                
                <textarea name="message" required id='formtexto' placeholder='Mensagem' />
                <input type="submit" value="ENVIAR" className='enviar' />
            </form>
            {envio &&
            <div className='enviada'>Sua mensagem foi enviada com sucesso, em breve retornaremos</div>
            }
            {erro &&
             <div className='erroenvio'>Ocorreu um erro no seu envio, por favor tente mais tarde!</div>
            }

        </div>
    </section>
  )
}

export default Formulario