import React from 'react'
import './home.css'
import I11 from'../../../Midia/41.svg'
import I12 from'../../../Midia/42.svg'
import I13 from'../../../Midia/43.svg'
import I14 from'../../../Midia/44.svg'
import I15 from'../../../Midia/45.svg'
import I16 from'../../../Midia/46.svg'
import I17 from'../../../Midia/47.svg'
import I18 from'../../../Midia/48.svg'
import I19 from'../../../Midia/49.svg'
import I20 from'../../../Midia/50.svg'

function Premios() {
  return (
    <section className='sec1' id='premios'>
        <div className="liderancas">
        <div className="vazia"></div>
            <div className='titulo'>Principais <span>Prêmios</span></div>
       
            <div className="premiosGrid">
                <div className="premioItem"><div className="premioItem-I"> <img src={I11} alt="colégio ao cubo" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I12} alt="aqua rio" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I13} alt="bio parque" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I14} alt="allos" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I15} alt="instituto delete" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I16} alt="curso damásio" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I17} alt="cyrela" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I18} alt="dersani" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I19} alt="dersani" width={'100%'} height={'100%'}/></div></div>
                <div className="premioItem"><div className="premioItem-I"><img src={I20} alt="dersani" width={'100%'} height={'100%'}/></div></div>               
            </div>
        </div>

    </section>
  )
}

export default Premios