import React from 'react'
import './header.css'
import Youtube from '../../Midia/youtube.webp'
import Linked from '../../Midia/linked_branco.webp'
import Face from '../../Midia/face.webp'
import Insta from '../../Midia/insta.webp'

function Rodape() {
  return (
    <footer>
        <div className="footerContainer">
            <div className='footercontatos'>
                <div className='footerMidias'>
                    <a href="https://www.instagram.com/camisa10ag/" target="_blank" rel="noopener noreferrer"><img src={Insta} alt="Instagram" width={'25px'} height={'25px'} /></a>
                    <a href="https://www.facebook.com/camisa10propaganda" target="_blank" rel="noopener noreferrer"><img src={Face} alt="facebook" width={'25px'} height={'25px'} /></a>
                    <a href="https://br.linkedin.com/company/camisa10agencia" target="_blank" rel="noopener noreferrer"><img src={Linked} alt="Linkedin" width={'25px'} height={'25px'} /></a>
                    <a href="https://www.youtube.com/@camisa10propaganda10" target="_blank" rel="noopener noreferrer"><img src={Youtube} alt="youtube" width={'36px'} height={'25px'} /></a>
                </div>
                <div className='footerEmail'><a href="mailto:contato@camisa10.ag">contato@camisa10.ag</a></div>
            </div>
            <div className="footerCopy">Copyright 2023 Camisa 10 | Todos os direitos reservados </div>
        </div>
    </footer>
  )
}

export default Rodape