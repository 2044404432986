import React from 'react'

function Section2() {
  return (
    <section className="sec1">
        <div className="container">
            <div className="textoRegular">
            Se a concorrência vai pra lá, vem pra cá.
            </div>
            <main className="textoPeq">
                Usamos nosso “GPS do mercado”, aperfeiçoado por 18 anos de estrada, para mapear oportunidades e transformar negócios, por meio da criatividade. Somos ágeis e criativos em todos os processos da agência, do briefing à entrega final. E é dessa forma que conseguimos entregar resultados de alta performance e construir marcas sólidas. 
            </main>
            <div className="textoCTA">
                Conheça a Camisa 10 e comece, desde já, a imaginar o que podemos fazer pela sua marca.
            </div>
        </div>
    </section>
  )
}

export default Section2