import React from 'react'
import './home.css'
import I10 from'../../../Midia/10.svg'
import I11 from'../../../Midia/11.svg'
import I12 from'../../../Midia/12.svg'
import I13 from'../../../Midia/13.svg'
import I15 from'../../../Midia/15.svg'
import I16 from'../../../Midia/16.svg'
import I17 from'../../../Midia/17.svg'
import I18 from'../../../Midia/18.svg'
import I21 from'../../../Midia/21.svg'
import I22 from'../../../Midia/22.svg'
import I23 from'../../../Midia/23.svg'
import I24 from'../../../Midia/24.svg'
import I25 from'../../../Midia/25.svg'
import I26 from'../../../Midia/26.svg'
import I27 from'../../../Midia/27.svg'
import I28 from'../../../Midia/28.svg'
import I31 from'../../../Midia/31.svg'
import I32 from'../../../Midia/32.svg'
import I33 from'../../../Midia/33.svg'
import I34 from'../../../Midia/34.svg'
import I35 from'../../../Midia/35.svg'
import I37 from'../../../Midia/37.svg'
import I38 from'../../../Midia/38.svg'
import radix from'../../../Midia/radix.svg'




function Cliente() {
  return (
    <section className='sec1' id='clientes'>
        <div className="liderancas">
        <div className="vazia"></div>
            <div className='titulo'>Clientes <span>Atendidos</span></div>
       
            <div className="clientesGrid">
            <div className="cliente"><img src={I10} alt="allos" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I11} alt="colégio ao cubo" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I12} alt="aqua rio" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I13} alt="bio parque" width={'100%'} height={'100%'}/></div>
               
                <div className="cliente"><img src={I15} alt="instituto delete" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I16} alt="curso damásio" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I17} alt="cyrela" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I18} alt="dersani" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I21} alt="estácio" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I22} alt="fqm" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I23} alt="instituto yduqs" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I24} alt="ibeu" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I25} alt="juçai" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I26} alt="naturgy" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I27} alt="l'oreal" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I28} alt="olx" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I31} alt="petrobras" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={radix} alt="radix" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I32} alt="rio + saneamento" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I33} alt="stanley" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I34} alt="silimed" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I35} alt="uni horizontes" width={'100%'} height={'100%'}/></div>
                
                <div className="cliente"><img src={I37} alt="vale" width={'100%'} height={'100%'}/></div>
                <div className="cliente"><img src={I38} alt="yduqs" width={'100%'} height={'100%'}/></div>
            </div>
        </div>

    </section>
  )
}

export default Cliente