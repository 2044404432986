import React from 'react'
import { Link } from 'react-router-dom'
import './cases.css'
import cases from '../../../Json/cases.json'

function ListaCases() {
  return (
    <section className='secCases'>
        {[...cases].map(item=>{return(
        <div className="caseContainer" key={item.id}>
            <Link to={'/case/'+item.cliente}>
              <div className="cases">
                <div className="casesImag">
                    <img src={item.thumb} alt={item.cliente} width={'100%'} height={'100%'}/>
                </div>
                <div className={item.id === 4 || item.id=== 8? "casesCTAL" :"casesCTA"}>
                  <div className="casesChamada">{item.chamada}</div>
                  <div className="casesCLiente">{item.cliente}</div>
                </div>
              </div>
            </Link>
        </div>
      )})}
    </section>
  )
}

export default ListaCases