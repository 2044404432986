
import RouteList from './Routes/RouteList';

function App() {
  return (
    <div className="App">
      
       <RouteList />

    </div>
  );
}

export default App;
