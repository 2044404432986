import React  from 'react'
import HeaderCases from '../Components/Partes/HeaderCases'
import Rodape from '../Components/Partes/Rodape'
import Formulario from '../Components/Partes/Formulario'
import Chamada from '../Components/Pages/Cases/Chamada'
import ListaCases from '../Components/Pages/Cases/ListaCases'
import './pages.css'

function Cases() {

  return (
    <div className="superContainer">
      <HeaderCases/>
      <section className='homeBody'>
      <Chamada/>
      <ListaCases />
      <Formulario />
      <Rodape />
    </section>
    </div>
  )
}

export default Cases