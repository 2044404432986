import React, {useEffect} from 'react'
import HeaderProj from '../Components/Partes/HeaderProj'
import Rodape from '../Components/Partes/Rodape'
import Formulario from '../Components/Partes/Formulario'
import ProjetoLista from '../Components/Pages/Projeto/ProjetoLista'
import './pages.css'

function Projeto() {
  useEffect(()=>{window.scrollTo(0,0);},[])
  return (
    <>
    <HeaderProj />
    <section className='homeBodyP'>
      <ProjetoLista />
      <Formulario />
      <Rodape />
    </section>
    </>

  )
}

export default Projeto