import React, {useState} from 'react';
import './header.css';
import Logo from '../../Midia/logo.svg'
import Hamburguer from '../../Midia/hamburguer.svg'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function HeaderCases() {
  const [abre, setAbre]=useState(false)

  const handeleToggle=()=>{
    setAbre(!abre)
  }


  return (
    <div className="headerContainer">
      <header>
          <div className="logo">
          <Link to='/'><img src={Logo}  alt="Logo Camisa 10" width={'100%'} height={'100%'} /></Link>
          </div>
          <nav>
             <ul className="naveg">
                <li><Link to='/'>HOME</Link></li>
                <li><Link to='/cases'>CASES</Link></li>
                <li><HashLink smooth to="/#liderancas">LIDERANÇAS</HashLink></li>
                <li><HashLink smooth to="/#eco">ECOSSISTEMA</HashLink></li>
                <li><HashLink smooth to="/#clientes">CLIENTES</HashLink></li>
                <li><HashLink smooth to="/#premios">PRÊMIOS</HashLink></li>
                <li><HashLink smooth to="#contato">CONTATO</HashLink></li>
             </ul>
          </nav>
          <div className="navMob">
              <div className="hamburguer">
                    <img src={Hamburguer} alt="Menu hamburguer" width={'100%'} height={'100%'} onClick={handeleToggle} />
              </div>
          </div>
          
      </header>
      <ul className={abre? "navegMob activeMob": "navegMob" } >
          <li></li>
            <li><Link to='/'>HOME</Link></li>
            <li><Link to='/cases'>CASES</Link></li>
            <li><HashLink smooth to="/#liderancas">LIDERANÇAS</HashLink></li>
            <li><HashLink smooth to="/#eco">ECOSSISTEMA</HashLink></li>
            <li><HashLink smooth to="/#clientes">CLIENTES</HashLink></li>
            <li><HashLink smooth to="/#premios">PRÊMIOS</HashLink></li>
            <li><HashLink smooth to="#contato">CONTATO</HashLink></li>
            <li></li>
            <li></li>
      </ul>
    </div>
  )
}

export default HeaderCases