import React from 'react'
import './notfound.css'
import { Link } from 'react-router-dom'
import Logo from '../Midia/logoG.webp'

function NotFound() {
  return (
    <div className="tela">
        <div className="content404">
            <div className="txt404">404</div>
            <div className="txtVolta">
                <div className="logo404">
                    <img src={Logo} alt="Camisa 10" width={'120px'} height={'auto'} />
                    <p>Página não encontrada. Volte para a <Link to={'/'}>Home</Link>.</p>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default NotFound