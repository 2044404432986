import React from 'react'

function Ecossistema() {
  return (
    <section className='sec1' id='eco'>
        <div className="liderancas">
            <div className="vazia"></div>
            <div className='titulo'>NOSSAS <span>ENTREGAS</span></div>
            <div className="ecoGrid">
                <div className="ecoContainer">
                    <div className="ecotitulo">OFF <span>Line</span></div>
                    <ul className='ecolista'>
                        <li>Plano de Mídia</li>
                        <li>Campanha de Mídia</li>
                        <li>Keyvisual</li>
                        <li>Mídia OOH</li>
                        <li>Roteiros de Filmes e Spots</li>
                        <li>Estratégia e Ativação de PDV</li>
                    </ul>
                </div>
                <div className="ecoContainer">
                    <div className="ecotitulo">on <span>Line</span></div>
                    <ul className='ecolista'>
                        <li>Estratégia de MKT Digital</li>
                        <li>Plano de Mídia On</li>
                        <li>Landing Pages</li>
                        <li>Roteiros de Filmes e Spots</li>
                        <li>Vídeos, Motion e Captação</li>
                        <li>Performance</li>
                    </ul>

                </div>
                <div className="ecoContainer">
                    <div className="ecotitulo">Social <span>Media</span></div>
                    <ul className='ecolista'>
                        <li>Planejamento de Editorias</li>
                        <li>Estratégia de Conteúdo</li>
                        <li>Criação de Conteúdo</li>
                        <li>Ação com Influenciadores</li>
                        <li>Gerenciamento de Perfis</li>
                        <li>Social Listening</li>
                    </ul>
                </div>
                <div className="ecoContainer">
                    <div className="ecotitulo">Endo<span>mkt</span></div>
                    <ul className='ecolista'>
                        <li>Planejamento de Campanha</li>
                        <li>Peças de Comunicação</li>
                        <li>Ações de Engajamento</li>
                        <li>Vídeos Institucionais</li>
                        <li>Brindes Diferenciados</li>
                    </ul>
                </div>
                <div className="ecoContainer">
                    <div className="ecotitulo">brand<span>ing</span></div>
                    <ul className='ecolista'>
                        <li>Criação de Identidade Visual</li>
                        <li>Arquitetura de Marcas</li>
                        <li>Brandbook</li>
                        <li>Embalagens</li>
                        <li>Projetos de Sinalização</li>
                    </ul>
                </div>
                <div className="ecoContainer"></div>

            </div>
        </div>
    </section>
  )
}

export default Ecossistema