import React from 'react'
import Header from '../Components/Partes/Header'
import Section1 from '../Components/Pages/Home/Section1'
import Video from '../Components/Pages/Home/Video'
import Lideranças from '../Components/Pages/Home/Lideranças'
import Cliente from '../Components/Pages/Home/Cliente'
import Premios from '../Components/Pages/Home/Premios'
import Rodape from '../Components/Partes/Rodape'
import Formulario from '../Components/Partes/Formulario'
import Ecossistema from '../Components/Pages/Home/Ecossistema'
import Section2 from '../Components/Pages/Home/Section2'
import './pages.css'
function Home() {

  return (
    <>
    <Header />
    <section className='homeBody'>
      <Section1 />
      <Video />
      <Section2 />
      <Lideranças />
      <Ecossistema />
      <Cliente />
      <Premios />
      <Formulario />
      <Rodape />
    </section>
    </>
  )
}

export default Home